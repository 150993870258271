import React from 'react'
import PropTypes from 'prop-types'
import Address from './Address'

const Contacts = ({ contacts }) => (
  <div>
    {contacts.map((contact) => (
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="columns">
            <div className="column is-7">
              <h3 className="has-text-weight-semibold is-size-3">
                  {contact.title}
              </h3>
              <Address address={contact.address}/>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

Contacts.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      address: PropTypes.array,
    })
  ),
}

export default Contacts
