import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Contacts from '../components/Contacts'

export const ContactPageTemplate = ({
  title,
  image,
  contacts,
}) => (
  <div className="content">
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
      }}
    >
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen" style={{
            boxShadow: 'rgb(51, 153, 51) 0.5rem 0px 0px, rgb(51, 153, 51) -0.5rem 0px 0px',
            backgroundColor: 'rgb(51, 153, 51)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em'
            }} >
            {title}
        </h1>
    </div>
    <section className="section--gradient">
      <div className="container">
        <div>
          <Contacts contacts={contacts} />
        </div>
      </div>
    </section>
    <div className="section"> </div>
  </div>
)

ContactPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  address: PropTypes.array,
}

const ContactPg = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ContactPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        contacts={frontmatter.contacts}
      />
    </Layout>
  )
}

ContactPg.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPg

export const contactPageQuery = graphql`
  query ContactPg($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contacts {
           title
           address {
              line1
              line2
              line3
              state
              phone
              email
              url
           }
        }
      }
    }
  }
`
