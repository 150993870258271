import React from 'react'
import PropTypes from 'prop-types'
import Iframe from 'react-iframe'

const Address = ({ address }) => (
  <div>
     <div className="columns">
       <div className="column is-10 is-offset-1">
         <div className="columns">
           <div className="column is-7">
             <p>{address.line1}</p>
             <p>{address.line2}</p>
             <p>{address.line3}</p>
             <p>{address.state}</p>
             <p>Phone: {address.phone}</p>
             <p>Email: {address.email}</p>
           </div>
           <div>
             <Iframe url={address.url}
                    id="mapId" height="300px" width="400px"
                    className="map-frame"
               />
           </div>
        </div>
       </div>
     </div>
  </div>
)

Address.propTypes = {
  address: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default Address


